import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../styles/media'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { FlexCenter } from '../CssTools'


const StyledWrap = styled.div`
${() => media.tablet(css`
  max-width:500px;
  margin:32px auto;
`)}
  width:100%;
  display:flex;
  flex-flow:row wrap;
  align-items:center;
  justify-content:space-between;
  background:${({ theme }) => theme.colors.primary.brown};
  padding-bottom:32px;
  margin-bottom:32px;
  p{
    font-size:12px;
    line-height:1.1em;
  }
  h3{
    width:100%;
    font-size:12px;
    margin:30px 0 16px;
    ${FlexCenter}
    line-height:14px;
    flex-flow:row nowrap;
    span{
      font-family:${({ theme }) => theme.colors.font.eng};
      margin:0 8px;
      font-size:14px;
      font-weight:800;
    }
  }
`

const StyledPhoto2 = styled.div`
  display:flex;
  flex-flow:row nowrap;
  align-items:center;
  justify-content:center;
  .male,.female{
    background:white;
    width:70px;
    height:70px;
    border-radius:50%;
    position:relative;
    img{
      position:absolute;
      width:34px;
      height:40px;
      left:18px;
      top:15px;
    }
  }
  .mf{
    width:40%;
    margin:0 15px;
  }
`

const MaleFemale = () => {
  const data = useStaticQuery(query)
  return (
    <StyledWrap>
      <h3>
        <span>[</span>
        {data.text.tendToLack.imgtxt[0]}
        <span>]</span>
      </h3>
      <StyledPhoto2>
        <StaticImage
          src='../../images/male21.svg'
          placeholder='none'
          alt='男性に必要な食物繊維'
          className='male'
        />
        <StaticImage
          src='../../images/toiletImage.svg'
          placeholder='none'
          alt='男女'
          className='mf'
        />
        <StaticImage
          src='../../images/female18.svg'
          placeholder='none'
          alt='女性に必要な食物繊維'
          className='female'
        />
      </StyledPhoto2>
    </StyledWrap>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: {eq: "bitsOfKnowledgePage"}) {
      tendToLack {
        imgtxt
      }
    }
  }
`

export default MaleFemale
