import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../styles/media'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { FlexCenter } from '../CssTools'


const StyledWrap = styled.div`
${() => media.tablet(css`
  max-width:500px;
  margin:16px auto 32px;
`)}
  width:100%;
  display:flex;
  flex-flow:row wrap;
  align-items:flex-start;
  justify-content:space-between;
  background:${({ theme }) => theme.colors.primary.brown};
  padding-bottom:32px;
  margin-bottom:32px;
  p{
    font-size:12px;
    line-height:1.1em;
  }
  h3{
    width:100%;
    font-size:12px;
    margin:30px 0 16px;
    ${FlexCenter}
    line-height:14px;
    flex-flow:row nowrap;
    span{
      font-family:${({ theme }) => theme.colors.font.eng};
      margin:0 8px;
      font-size:14px;
      font-weight:800;
    }
  }
`

const StyledPhoto2 = styled.div`
  width:300px;
  height:300px;
  margin:0 auto;
  position:relative;
  display:flex;
  flex-flow:row nowrap;
  align-items:center;
  justify-content:center;
  ${() => media.tablet(css`
    width:100%!important;
  `)}
  .base{
    width:300px;
    height:auto;
    position:absolute;
  }
  .parts{
    width:150px;
    height:auto;
    position:absolute;
    bottom:30px;
  }
`

const StyledComment = styled.div`
  width:100%;
`


const GraphImage = () => {
  const data = useStaticQuery(query)
  return (
    <StyledWrap>
      <h3>
        <span>[</span>
        {data.text.tendToLack.imgtxt[1]}
        <span>]</span>
      </h3>
      <StyledPhoto2>
        {/* <StaticImage
          src='../../images/graphBody.svg'
          placeholder='none'
          alt='グラフ'
          className='base'
        />
        <StaticImage
          src='../../images/numbers.svg'
          placeholder='none'
          alt='グラフ'
          className='number'
        />
        <StyledComment>
          <StaticImage
            src='../../images/graphComment.svg'
            placeholder='none'
            alt='グラフ'
            className='comment'
          />
          <p>{data.text.tendToLack.imgtxt[2]}</p>
        </StyledComment>
        <StaticImage
          src='../../images/graphParts.svg'
          placeholder='none'
          alt='グラフ'
          className='parts'
        /> */}
        <StaticImage
          src='../../images/graphFull.svg'
          placeholder='none'
          alt='グラフ'
          className='base'
        />
      </StyledPhoto2>
    </StyledWrap>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: {eq: "bitsOfKnowledgePage"}) {
      tendToLack {
        imgtxt
      }
    }
  }
`

export default GraphImage
